(function() {
  var onScroll, setProductPresentationPadding, showNewsletterSubscriptionPopUp;

  onScroll = function(event) {
    var distanceY, header, scrollPos, shrinkOn;
    scrollPos = $(document).scrollTop();
    distanceY = window.pageYOffset || document.documentElement.scrollTop;
    shrinkOn = 658;
    header = document.querySelector('header');
    if (distanceY > shrinkOn) {
      $(header).addClass('small');
    } else {
      if ($(header).hasClass('small')) {
        $(header).removeClass('small');
      }
    }
    $('.js-nav-link-section').each(function() {
      var currLink, refElement;
      currLink = $(this);
      refElement = $(currLink.attr('href'));
      if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
        $('.js-nav-link-section').removeClass('active');
        return currLink.addClass('active');
      } else {
        return currLink.removeClass('active');
      }
    });
  };

  setProductPresentationPadding = function(event) {
    var header;
    header = document.querySelector('header').offsetHeight;
    if (window.innerWidth < 768) {
      $('.product-presentation').css('padding-top', header + 60);
    } else {
      $('.product-presentation').css('padding-top', header + 80);
    }
  };

  showNewsletterSubscriptionPopUp = function() {
    require(['mojo/signup-forms/Loader'], function(L) {
      L.start({
        'baseUrl': 'mc.us12.list-manage.com',
        'uuid': '7c2be14f68962e012c0ce7067',
        'lid': '360ec4b471'
      });
    });
    document.cookie = 'MCPopupClosed=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    document.cookie = 'MCPopupSubscribed=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  };

  $(function() {
    $('.js-open-newsletter-popup').on('click', showNewsletterSubscriptionPopUp);
    if ($('.product-presentation').length > 0) {
      setProductPresentationPadding();
      $(document).on('scroll', onScroll);
      $('.js-nav-link-section').on('click', function(e) {
        var $target, menu, target;
        e.preventDefault();
        $(document).off('scroll');
        $('.js-nav-link-section').removeClass('active');
        $(this).addClass('active');
        target = this.hash;
        menu = target;
        $target = $(target);
        $('html, body').stop().animate({
          'scrollTop': $target.offset().top + 2
        }, 500, 'swing', function() {
          window.location.hash = target;
          $(document).on('scroll', onScroll);
        });
      });
    }
  });

  window.onresize = function(event) {
    return setProductPresentationPadding();
  };

}).call(this);
