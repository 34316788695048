onScroll = (event) ->
  scrollPos = $(document).scrollTop()

  distanceY = window.pageYOffset or document.documentElement.scrollTop
  shrinkOn = 658
  header = document.querySelector('header')
  if distanceY > shrinkOn
   $(header).addClass('small')
  else
    if $(header).hasClass('small')
      $(header).removeClass('small')

  $('.js-nav-link-section').each ->
    currLink = $(this)
    refElement = $(currLink.attr('href'))
    if refElement.position().top <= scrollPos and refElement.position().top + refElement.height() > scrollPos
      $('.js-nav-link-section').removeClass 'active'
      currLink.addClass 'active'
    else
      currLink.removeClass 'active'
  return

setProductPresentationPadding = (event) ->
  header = document.querySelector('header').offsetHeight
  if window.innerWidth < 768
    $('.product-presentation').css('padding-top', header + 60)
  else
    $('.product-presentation').css('padding-top', header + 80)
  return

showNewsletterSubscriptionPopUp = () ->
  require [ 'mojo/signup-forms/Loader' ], (L) ->
    L.start
      'baseUrl': 'mc.us12.list-manage.com'
      'uuid': '7c2be14f68962e012c0ce7067'
      'lid': '360ec4b471'
    return
  document.cookie = 'MCPopupClosed=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;'
  document.cookie = 'MCPopupSubscribed=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;'
  return

$ ->
  $('.js-open-newsletter-popup').on 'click', showNewsletterSubscriptionPopUp
  if ($('.product-presentation').length > 0)
    setProductPresentationPadding()
    $(document).on 'scroll', onScroll
    $('.js-nav-link-section').on 'click', (e) ->
      e.preventDefault()
      $(document).off 'scroll'
      $('.js-nav-link-section').removeClass 'active'
      $(this).addClass 'active'
      target = @hash
      menu = target
      $target = $(target)
      $('html, body').stop().animate { 'scrollTop': $target.offset().top + 2 }, 500, 'swing', ->
        window.location.hash = target
        $(document).on 'scroll', onScroll
        return
      return
    return

window.onresize = (event) ->
 setProductPresentationPadding()
